/* Global css starts */

body {
    font-family: 'Poppins', sans-serif;
    background: #040617;
}

/* Global css ends */
/* Splash page starts */

.splash-bg {
    background: linear-gradient(180deg, rgba(9, 28, 202, 0.6) 0%, rgba(19, 25, 80, 0.6) 71.04%, rgba(3, 5, 30, 0.6) 100%), url('./res/images/splash-bg.jpg') no-repeat;
    /* background-image: url("./res/images/splash-bg.svg"); */
    /* min-height: 100vh; */
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.splash-bg .afn-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0 30px;
}

.splash-bg .afn-logo img {
    max-width: 400px;
}

/* Splash page ends */
/* Sidebar starts */

.afn-sidebar {
    width: 200px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #040720;
    display: flex;
    flex-direction: column;
    transition: 0.3s all ease-out;
}

.afn-sidebar .side-menus {
    overflow-y: auto;
}

.afn-sidebar .side-menus::-webkit-scrollbar {
    display: none;
}

.afn-sidebar::-moz-scrollbar {
    display: none;
}

.afn-sidebar .logo-header {
    padding: 30px 50px;
    text-align: center;
}

.afn-sidebar .logo-header img {
    max-width: 100px;
}

.afn-sidebar .side-menu-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.afn-sidebar .side-menu-list li {
    display: block;
}

.afn-sidebar .side-menu-list li a {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #BDC2E3;
    text-decoration: none;
    padding: 5px 0px 5px 30px;
    position: relative;
    display: flex;
    align-items: center;
}

.afn-sidebar .side-menu-list li a img {
    margin-right: 12px;
    width: 24px;
}

.afn-sidebar .side-menu-list li a:hover,
.afn-sidebar .side-menu-list li a.active {
    background: #13173C;
}

.afn-sidebar .side-menu-list li a:hover::after,
.afn-sidebar .side-menu-list li a.active::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: #1FAFFF;
}

.afn-sidebar .side-menu-list li:not(:last-child) a {
    margin-bottom: 25px;
}

.afn-sidebar .side-menu-list.list-2 {
    margin-top: 40px;
}

.afn-sidebar .side-menu-list.list-bottom {
    margin-top: auto;
    padding-bottom: 100px;
    padding-top: 100px;
}

.afn-sidebar .sidebar-toggle {
    display: none;
}

/* Sidebar ends */
/* Page body starts */

.afn-page-body {
    width: calc(100% - 200px);
    margin-left: 200px;
    height: calc(100vh - 80px);
    padding: 50px 50px 100px 50px;
    background: radial-gradient(236.83% 43.6% at 5.73% -15.48%, #283DFF 0%, rgba(0, 0, 0, 0) 100%), #040617;
    transition: 0.3s all ease-out;
    overflow-y: auto;
}

.geoBlockErrorButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.geoBlockErrorButton {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 20px;
}

.afn-page-body::-webkit-scrollbar {
    display: none;
}

.afn-page-body .page-heading {
    display: flex;
    align-items: center;
}

.afn-page-body .page-heading .page-title {
    display: inline-block;
    margin-left: 70px;
    color: #fff;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 0;
}

.afn-page-body .page-head {
    margin-bottom: 35px;
}

.afn-page-body .page-head .input-group {
    max-width: 270px;
    margin-left: auto;
    background: #FFFFFF;
    border-radius: 4px;
    height: 50px;
}

.afn-page-body .page-head .input-group .form-control,
.afn-page-body .page-head .input-group .form-control::placeholder {
    border: none;
    border-radius: 4px;
    color: #13173C;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    box-shadow: none;
    padding-left: 0px;
}

.afn-page-body .page-head .input-group .input-group-text {
    background: transparent;
}

/* Page body ends */
/* Playing song page starts */

.afn-player-wrapper {
    position: relative;
    text-align: center;
}

.radio-list {
    align-items: flex-end;
}

.radio-list a {
    color: #fff;
}

.afn-player-wrapper .cover-image {
    max-width: 250px;
    margin-bottom: 45px;
}

.afn-player-wrapper .minimize {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.afn-player-wrapper .player-head {
    text-align: center;
}

.afn-player-wrapper .player-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #fff;
    margin-bottom: 15px;
}

.afn-player-wrapper .player-category {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.04em;
    color: #B4B8D6;
}

.afn-player-wrapper .tracker {
    position: relative;
    margin: 0 auto;
    width: 80%;
}

.afn-player-wrapper .tracker .track-line {
    width: 100%;
    display: block;
    height: 3px;
    background: #3C4160;
    border-radius: 46px;
    position: relative;
}

.afn-player-wrapper .tracker .track-progress {
    position: absolute;
    width: 300px;
    height: 3px;
    background: #FFF7F9;
    border-radius: 50px;
    top: 0;
    left: 0;
}

.afn-player-wrapper .tracker .time-starts {
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 25px;
}

.afn-player-wrapper .tracker .time-ends {
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    top: 25px;
}

.afn-player-wrapper .tracker .circle {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 300px;
    top: -5px;
    border-radius: 50px;
    background: #1FAFFF;
    border: 1px solid #FDFDFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.afn-player-wrapper .play-actions {
    margin: 75px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.afn-player-wrapper .play-actions .play-icon {
    width: 80px;
    height: 80px;
    margin: 0px 50px;
    background: #1FAFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.afn-player-wrapper .tracker.controls {
    max-width: 370px;
    margin-bottom: 50px;
}

/* Playing song page ends */
/* Playcard footer starts */

.playcard-footer {

    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    flex-direction: column;
}

.playcard-footer .playcard-right {
    padding: 20px 40px 20px 0px;
}

.playcard-footer .playcard-right .card-actions {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.playcard-footer .playcard-right .card-actions li {
    display: inline-block;
    cursor: pointer;
}

.playcard-footer .playcard-right .card-actions li:not(:last-child) {
    padding-right: 30px;
}

/* Playcard footer ends */
/* Radio list starts */

.radio-row {
    margin-left: 85px;
}

.radio-list-card {
    background: #13173C;
    border-radius: 4px;
    padding: 15px 15px 15px 25px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio-list-card .card-dots {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #4300FF;
    margin-right: 25px;
    display: block;
}

.radio-list-card .card-dots.blue {
    background: #5DACF5;
}

.radio-list-card .card-dots.yellow {
    background: #FFB13B;
}

.radio-list-card .card-dots.dark-blue {
    background: #3B4FFF;
}

.radio-list-card .card-dots.pink {
    background: #FB3BFF;
}

.radio-list-card .card-dots.green {
    background: #25B187;
}

.radio-list-card .card-dots.dark-yellow {
    background: #F47F29;
}

.radio-list-card .card-dots.red {
    background: #FB2E46;
}

.radio-list-card .card-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
}

.radio-list-card .status {
    letter-spacing: 0.02em;
    color: #B4B8D6;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px;
}

.radio-list-card .card-logo {
    margin-left: auto;
}

.radio-list-card .card-logo img {
    max-width: 100%;
    cursor: pointer;
}

.selectRegion {
    margin-left: 10px;
    max-width: 270px;
    border-radius: 4px;
    height: 50px;
}

.selectRegion:focus {
    border: none;
    box-shadow: none;
}

.next-privious-button {
    background: transparent;
    border: none;
}

button.next-privious-button:disabled {
    opacity: .4;
}

/* Radio list ends */

.afn-full-page-body {
    width: 100%;
    height: 100vh;
    margin-left: 0;
}

.afn-full-page-body .input-group {
    margin: 0 auto;
}

.afn-full-page-body .player-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #ffffff;
    text-align: center;
}

.afn-full-page-body .page-head .input-group {
    width: 320px;
}

.afn-full-page-body .list-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
}

.listBox-header {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    padding: 18px 10px 0;
}

.radio-list-row {
    justify-content: center;
}

.listBox-card {
    background: #13173C;
    border-radius: 4px;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}

.listBox-card .row,
.listBox-header .row {
    width: 100%;
}

.list-left-textBox {
    size: 16px;
    line-height: 24px;
    color: #fff;
}

.list-middle-textBox {
    color: #fff;
    text-align: center;
}

.newListHeading {
    margin-top: 70px !important
}

.list-right-textBox img {
    cursor: pointer;
}

.footer-links {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    height: 80px;
    align-items: center;
    background: #13173C;
    width: 100%;
    border-top: 1px solid #040617;
}

.footer-links li {
    padding: 0 10px;
}

.footer-links li {
    border-right: 2px solid #B4B8D6;
}

.footer-links li:last-child {
    border: none;
}

.footer-links li a {
    color: #B4B8D6 !important;
    font-size: 18px;
    line-height: 27px;
    text-decoration: none;
    cursor: pointer;
}

.header-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative;
}

.radio-detail-box {
    background: #13173C;
    border-radius: 4px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
    cursor: pointer;
}

.hoverAbleCol {
    cursor: pointer;
}

.radio-detail-contentBox {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.radio-detail-contentBox h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.radio-detail-contentBox p {
    font-size: 12px;
    line-height: 18px;
    color: #D9DBEF;
    margin-bottom: 0;
}

.radio-header-logo {
    display: flex;
    margin-left: 20px;
}

.radio-header-back {
    display: flex;
    margin: 20px 0 10px 200px;
}

.radio-list-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
}

.radio-list-nav .selectRegion {
    height: 36px;
}

.radio-list-nav .page-heading {
    display: flex;
    margin-right: 15px;
}

.notSelectRegion {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    text-transform: capitalize;
    flex-direction: column;
}

.notSelectRegion a {
    color: #ffffff;
    text-decoration: none;
}

.stationRadioImage {
    background: #FFFFFF;
    border-radius: 4px;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    /* padding: 20px; */
    margin-bottom: 20px;
}

.stationRadioImage img {
    max-width: 100% !important;
    margin-bottom: 0 !important
}

.terms-content-box {
    background: #13173C;
    border-radius: 4px;
    margin: 30px 30px 50px;
    padding: 35px;
    color: #ffffff;
    min-height: 70vh;
}

.service-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.date-heading {
    font-size: 14px;
    line-height: 21px;
}

.terms-content-box p {
    font-size: 12px;
    line-height: 18px;
    color: #D9DBEF;
    text-align: left;
}

.terms-heading {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #ffffff;
    margin-bottom: 10px;
}

.service-heading-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

div#td_container {
    display: none;
}

.station-btn {
    background: #262F73;
    border-radius: 4px;
    align-items: center;
    padding: 4px 16px;
    border: none;
    color: #fff;
    min-width: 250px;
    margin: 10px 45px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    border-radius: 20px;
}

.button-logo-box {
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;
    justify-content: center;
    min-width: 500px;
    margin-top: 50px;
}

.list-body {
    padding-bottom: 200px !important;
}

.stationImg {
    width: 60px;
    height: 60px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.station-grid-box {
    display: flex;
    flex-wrap: wrap;
}

.station-grid-box>.europe-imgContainer {
    width: calc(20% - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.station-grid-box>.pacific-imgContainer {
    width: calc(25% - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chooseLocation {
    margin: 30px 0;
}

.modalCloseBox {
    position: relative;
}

.modalCloseBox span {
    position: absolute;
    right: 0;
    font-size: 20px;
    cursor: pointer;
}

.homeTextBox {
    color: #fff;
    text-align: center;
}

.button-container>div {
    text-align: center;
    margin-bottom: 20px;
}

.button-container img {
    max-width: 232px;
}

.storeIconBox {
    margin-bottom: 160px;
    display: flex;
    justify-content: center;
    margin: 10px 0 160px;
}

.storeIconBox img {
    max-width: 200px;
}

.NotFountText {
    color: #fff;
}

.stationHeadingText {
    margin: 20px 0 10px;
}

.NotFountText {
    text-align: center;
    text-transform: capitalize;
    font-size: 18px;
    color: red;
}

.appleStoreIcon,
.playStoreIcon {
    margin: 10px;
}

/* Range slider css */
.afn-player-wrapper .form-range {
    position: relative;
}

.input-range__track--active{
    background: #ffffff !important;
}
.input-range__track{
    background: #3C4160;
    height: 3px;
}
.input-range__slider {
    background: #283DFF;
    border: 1px solid #FDFDFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
    height: 15px;
    width: 15px;
}

.input-range__label-container{
    display: none;
}
.tvScheduleLink,
.tvScheduleLink:hover{
    color: #fff;
    font-weight: 500;
}
@media only screen and (min-width:768px) {
    .desktop-none {
        display: none;
    }

    .terms-content-box {
        margin: 30px 0px 50px;
    }

}

@media only screen and (max-width: 600px) {
    .terms-content-box {
        margin: 5px 15px 50px 15px;
        padding: 10px 15px;
    }

    .button-container {
        flex-direction: column;
    }

    .station-btn {
        margin: 10px;
        min-width: 250px;
    }

    .splash-bg {
        background: linear-gradient(180deg, rgba(9, 28, 202, 0.6) 0%, rgba(19, 25, 80, 0.6) 71.04%, rgba(3, 5, 30, 0.6) 100%), url('./res/images/splash-bg-mobile.jpg') no-repeat;
        background-size: cover;
        background-attachment: fixed
    }
    .station-grid-box>.europe-imgContainer,
    .station-grid-box>.pacific-imgContainer{
    width: calc(50% - 20px);
    }
}

@media only screen and (max-width:767px) {
    .button-container {
        min-width: 100%;
    }

    .mobile-none {
        display: none;
    }

    .afn-full-page-body {
        padding: 20px 0 60px !important
    }

    .afn-full-page-body .player-title {
        font-size: 20px;
        line-height: 30px;
    }

    .input-group {
        position: absolute;
        bottom: 0;
        right: -241px;
        width: 250px;
        float: right;
        top: -3px;
        z-index: 0;
        box-shadow: none;
        transition-duration: .5s;
        overflow-y: scroll;
        background: transparent !important;
    }

    .input-group span {
        border: none;
    }

    .input-group-collapsed {
        right: 10px;
        background-color: #fff !important;
    }

    .input-group-collapsed span {
        border: 1px solid #ced4da;
    }

    /* .afn-full-page-body .page-head .input-group {
            min-width: 100%;
        } */
    .footer-links li a {
        font-size: 12px;
        line-height: 18px;
    }

    .playcard-footer {
        height: auto;
    }

    .radio-header-back {
        margin-left: 20px;
    }

    .radio-detail-box img {
        max-width: 100%;
    }

    .station-imgBox {
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border-radius: 4px;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* padding: 5px; */
    }

    .terms-header-mobile-menu {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }

    .terms-back {
        position: absolute;
        left: 20px;
    }

    .station-grid-box>.station-imgContainer {
        width: calc(25% - 20px);
        height: 120px
    }

    .station-imgContainer img {
        width: 120px;
        height: 120px;
    }

    .station-btn {
        width: max-content;
    }

    .button-container>div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .button-container>div:first-child {
        margin-bottom: 30px;
    }

    .splash-bg .afn-logo img {
        max-width: 100%;
    }

    .storeIconBox {
        margin-bottom: 140px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }

    .appleStoreIcon,
    .playStoreIcon {
        margin-bottom: 10px;
    }

    .location-menu-header img {
        margin-left: 10px;
    }

    .location-menu-header .input-group {
        right: -221px
    }

    .location-menu-header .input-group-collapsed {
        right: 10px
    }

    .stationHeadingText {
        margin: 20px 0 5px !important;
        font-size: 18px !important;
    }

    .NotFountText {
        text-transform: capitalize;
        color: red;
        text-align: center;
        margin: 0 auto;
        display: block;
        font-size: 14px;
    }
    .playerBox{
        height: auto !important;
    }
    .playerIcon {
        width: 60px !important;
    }
    .playerTextBox {
        padding: 0px 20px !important;
    }
    .playerControlIcon {
        padding: 0 10px;
    }
    .playerControlIcon img {
        width: 25px;
    }
}

@media screen and (max-width: 1400px) {
    .radio-row {
        margin-left: -10px;
    }

    .afn-page-body {
        padding: 50px;
    }
    .afn-player-wrapper .tracker.controls {
        margin-bottom: 100px;
    }

    .afn-page-body .page-heading .page-title {
        margin-left: 30px;
        font-size: 24px;
        line-height: 40px;
    }
}

@media screen and (max-width: 991px) {
    .afn-sidebar .sidebar-toggle {
        display: block;
        position: absolute;
        right: -25px;
        top: 20px;
        cursor: pointer;
    }

    .afn-sidebar .sidebar-toggle .navbar-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background: #13173C;
        padding: 0;
    }

    .afn-sidebar .sidebar-toggle .navbar-toggler:focus,
    .afn-sidebar .sidebar-toggle .navbar-toggler:active {
        box-shadow: none;
        outline: none;
    }

    .afn-sidebar .sidebar-toggle .navbar-toggler-icon {
        background: url('./res/images/toggle-sidebar.svg') no-repeat;
        background-position: center;
    }

    .afn-sidebar .sidebar-toggle .close-icon {
        background: url('./res/images/close-sidebar.svg') no-repeat;
        background-position: center;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        display: none;
    }

    .sidebar-on .afn-sidebar .sidebar-toggle .close-icon {
        display: block;
    }

    .sidebar-on .afn-sidebar .sidebar-toggle .navbar-toggler-icon {
        display: none;
    }

    .afn-sidebar.active-sidebar .sidebar-toggle .navbar-toggler {
        width: 30px;
        height: 30px;
    }

    .afn-sidebar.active-sidebar .sidebar-toggle {
        right: -17px;
    }

    .afn-sidebar {
        width: 80px;
        z-index: 3;
    }

    .afn-sidebar.active-sidebar {
        width: 200px;
    }

    .afn-sidebar .logo-header {
        padding: 30px 10px;
    }

    .afn-sidebar .logo-header img {
        width: 60px;
    }

    .afn-sidebar .side-menu-list li a span {
        display: none;
    }

    .afn-sidebar.active-sidebar .side-menu-list li a span {
        display: block;
    }

    .afn-page-body {
        margin-left: 80px;
        width: calc(100% - 80px);
        padding: 50px 20px;
    }

    .afn-full-page-body {
        margin-left: 0px;
        width: 100%;
        padding: 50px 20px;
    }

    .sidebar-overlay {
        display: none;
    }

    .sidebar-on {
        overflow: hidden;
    }

    .sidebar-on .sidebar-overlay {
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .afn-player-wrapper .cover-image {
        max-width: 100%;
        margin-bottom: 20px;
        max-height: 100%;
    }

    .afn-player-wrapper .player-title {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .afn-player-wrapper .tracker .track-progress {
        width: 50px;
    }

    .afn-player-wrapper .tracker .circle {
        left: 50px;
    }

    .afn-player-wrapper .play-actions .play-icon {
        width: 50px;
        height: 50px;
        margin: 0px 50px;
    }

    .afn-player-wrapper .play-actions .play-icon img {
        width: 50%;
    }

    .playcard-footer .playcard-right {
        padding: 20px;
    }

    .afn-player-wrapper .tracker.controls {
        max-width: 60%;
    }

    .afn-page-body .page-heading .page-title {
        margin-left: 40px;
        font-size: 20px;
        line-height: 35px;
    }

    .afn-page-body .page-head .input-group {
        margin: 0 auto;
    }

    .radio-list-card {
        padding: 15px;
    }

    .radio-list-card .card-dots {
        margin-right: 15px;
    }

    .radio-list-card .card-title {
        font-size: 15px;
        line-height: 24px;
    }
}

@media screen and (max-width: 600px) {
    .afn-page-body .page-heading .page-title {
        margin-left: 20px;
        font-size: 14px;
        line-height: 30px;
    }

    .page-heading .back-icon {
        max-width: 20px;
    }

    .station-grid-box>.station-imgContainer {
        width: calc(50% - 20px);
        margin-bottom: 10px;
        height: 100%;
    }

    .station-imgContainer img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 374px) {
    .footer-links li {
        padding: 0 3px;
    }
}


/* geoblock Error css start */
.geoBlockError {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    position: fixed;
    flex-direction: column;
    z-index: 99;
}

.geoBlockErrorText {
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
}

.geoBlockErrorText h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
}

.geoBlockErrorText p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
}

@media only screen and (max-width: 767px) {
    .geoBlockErrorText {
        padding: 10px;
    }

    .geoBlockErrorText h3 {
        font-size: 20px;
        line-height: 25px;
    }

    .geoBlockErrorText p {
        font-size: 16px;
        line-height: 20px;
    }
    .list-body {
        padding-bottom: 150px !important;
    }
}

/* geoblock error css end */

.playerBox {
    height: 80px;
    display: flex;
    align-items: center;
    background: #13173C;
    position: relative;
    justify-content: space-between;
}
.progressLine{
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0;
}
.progressLine [class~="progressbar"]{
    background: #283DFF;
}   
.playerTextBox {
    padding: 15px 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.playerTextBox h5 {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
    
}
.playerTextBox h5 span::before{
    content: "";
    width: 4px;
    height: 4px;
    background: #D2D5EB;
    margin: 0 10px;
    border-radius: 20px;
    display: inline-flex;
    position: relative;
    top: -2px;
}
.playerTextBox p{
    font-size: 14px;
line-height: 21px;
margin-bottom: 0;
}
.playerTextBox p span{
    margin-right: 10px;
}
.playerDetailBox {
    display: flex;
}
.playerControlIcon{
    padding: 0 45px;
    color: #fff;
}
.playerIcon{
    width: 80px;
}